
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Details extends Vue {
  loadingBindings = false;

  async handleLoading(functionality: Promise<void>): Promise<void> {
    this.loadingBindings = true;
    await functionality;
    this.loadingBindings = false;
  }
}
